:root {
  scrollbar-gutter: stable;
  color-scheme: only light;  
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  scroll-margin-top: 10em;
}

.smooth-scrolling {
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
p a {
  color: #007aff;
}
a:hover {
  text-decoration: underline;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  /* Prevent huge font-sizes in landscape on iOS Safari */
  -webkit-text-size-adjust: none;

  /*
    Important: Without the unsetting for `sidebar-desktop-scroll-container` below, this triggers a bug with the law sidebar, where the whole page scrolls up when the button in the sticky sidebar receives focus.
    See
    - https://stackoverflow.com/questions/72742581/scrolling-bug-when-focusing-element-inside-position-sticky-container#:~:text=When%20an%20element%20in%20the,scrolls%20up%20by%20about%20440px.
    - https://issues.chromium.org/issues/40749247
    - https://github.com/w3c/csswg-drafts/issues/7931
  */
  scroll-margin-top: 130px;
}

#sidebar-desktop-scroll-container, #sidebar-desktop-scroll-container * {
  scroll-margin-top: unset;
}

@media (pointer: coarse) {
  input[type='text'] {
    font-size: 1rem !important;
  }
}

hr {
  margin: 0;
  height: 1px;
  border: none;
  background-color: currentColor;
  opacity: 0.1;
}

p, h1, h2, h3, h4, h5, h6, figure {
  margin: 0;
}

svg {
  flex-shrink: 0;
}

img {
  max-width: 100%;
  height: auto;
}

.rough-annotation {
  color: rgba(228, 177, 95, 0.2);
}

.fade-in {
  display: contents;
}
.fade-in > * {
  opacity: 1;
  transition: opacity 0.3s;
  @starting-style {
    opacity: 0;
  }
}